import React from 'react';
import cls from 'classnames';

import { TextArea as TextAreaTPA, TextAreaProps } from 'wix-ui-tpa';

import { classes } from './TextArea.st.css';

interface ITextFieldProps extends TextAreaProps {
  bw?: boolean;
}

export function TextArea(props: ITextFieldProps) {
  const { className, bw, ...rest } = props;

  return (
    <TextAreaTPA
      newErrorMessage
      className={cls(classes.root, className, {
        [classes.bw]: bw,
      })}
      {...rest}
    />
  );
}

TextArea.displayName = 'TextArea';
