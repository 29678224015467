import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import type { IFeedItem } from 'api/feed/types';
import { selectIsJoinedGroupMember } from 'store/selectors';
import { useController } from 'common/context/controller';

import { Card } from 'wui/Card';
import { Box } from 'wui/Box';
import { Stack } from 'wui/Stack';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Collapse } from 'wui/Collapse';
import { Divider } from 'wui/Divider';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';

import { Comments } from './Comments/loadable';
import { Reactions } from './Reactions/legacy/loadable';
import { FeedItemHeader } from './FeedItemHeader';
import { FeedItemContent } from './FeedItemContent';
import { FeedItemDisclaimer } from './FeedItemDisclaimer';
import { FeedItemActions } from './FeedItemActions';
import { ReactedMembers } from './ReactedMembers';

import { getDescribedBy, getLabelledBy } from './a11y';
import { FEED_ITEM } from './dataHooks';
import { FeedItemTopics } from './FeedItemTopics';

interface IProps extends React.ComponentProps<typeof Card> {
  focused?: boolean;
  item: IFeedItem;
  truncate?: boolean;
  promote?: boolean;
}

export function FeedItem(props: IProps) {
  const { item, truncate, promote, focused, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { comments$ } = useController();
  const { experiments } = useExperiments();

  const [expanded, setExpanded] = useState(!isMobile);
  const ref = React.useRef<HTMLDivElement>(null);

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const newReactions = experiments.enabled('specs.groups.NewReactions');
  const isJoined = useSelector(selectIsJoinedGroupMember(groupId));
  const suggestToJoin = !isJoined && promote;

  React.useEffect(() => {
    if (focused) {
      ref.current?.focus();
    }
  }, [focused, ref.current]);

  return (
    <Card
      ref={ref}
      role="article"
      tabIndex={0}
      data-hook={FEED_ITEM}
      sideBorders={!isMobile}
      aria-labelledby={getLabelledBy(feedItemId)}
      aria-describedby={getDescribedBy(feedItemId)}
      {...rest}
    >
      <Box padding="SP5 0" gap="SP5" direction="vertical">
        <Stack gap="SP3" direction="vertical" separator={<Divider inset />}>
          <Show if={!!item.pin}>
            <FeedItemDisclaimer variant="pinned" item={item} />
          </Show>
          <Show if={suggestToJoin}>
            <FeedItemDisclaimer variant="suggested" item={item} />
          </Show>
          <FeedItemHeader item={item} promote={promote} />
        </Stack>

        <FeedItemContent item={item} truncate={truncate} />

        <FeedItemTopics item={item} limit={isMobile ? 2 : 5} />

        <Hide if={newReactions}>
          <Reactions item={item} toggleComments={handleToggleComments} />
        </Hide>

        <ReactedMembers
          item={item}
          legacy={!newReactions}
          toggleComments={handleToggleComments}
        />

        <Show if={newReactions}>
          <FeedItemActions
            item={item}
            openCommentForm={handleOpenCommentForm}
          />
        </Show>

        <Collapse isOpened={expanded} unmountOnExit>
          <Comments
            item={item}
            fallback={<EmptyState variant="section" title={<Spinner />} />}
          />
        </Collapse>
      </Box>
    </Card>
  );

  function handleToggleComments() {
    setExpanded((expanded) => !expanded);
  }

  function handleOpenCommentForm() {
    setExpanded(true);

    comments$.openCommentBox(item.feedItemId as string, {
      shouldFocus: true,
      shouldScroll: true,
    });
  }
}

FeedItem.displayName = 'FeedItem';
