import React from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';

import { EGroupPartition } from 'api/groups/types';
import { EGroupsNamespace } from 'store/groups/constants';
import { selectGroups } from 'store/selectors';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { InputDialog } from 'wui/InputDialog';
import { TextField } from 'wui/TextField';
import { Box } from 'wui/Box';

import { SELECT_GROUP_MODAL_ROOT } from './dataHooks';
import { JoinedGroups } from './JoinedGroups';

import { classes } from './SelectGroupModal.st.css';

interface IProps extends React.ComponentProps<typeof InputDialog> {
  onSelect(groupId: string): void;
}

export function SelectGroupModal(props: IProps) {
  const { onSelect, ...rest } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { groups$ } = useController();

  const [query, setQuery] = React.useState<string>('');
  const { groups, status } = useSelector(
    selectGroups(EGroupsNamespace.JOINED_DIALOG),
  );

  const delayedQuery = React.useCallback(debounce(queryGroups, 500), []);

  React.useEffect(() => {
    if (rest.isOpen) {
      queryGroups('');
    }
  }, [rest.isOpen]);

  useDidUpdate(() => {
    delayedQuery(query);
  }, [query]);

  return (
    <InputDialog
      {...rest}
      paperProps={{
        className: !isMobile ? classes.root : undefined,
        'data-hook': SELECT_GROUP_MODAL_ROOT,
      }}
    >
      <DialogTitle
        title={t('groups-web.select-group-modal.title')}
        subtitle={
          <Box paddingTop="SP6" direction="vertical">
            <TextField
              bw
              value={query}
              withClearButton
              onClear={handleReset}
              prefix={<SearchIcon />}
              placeholder={t('groups-web.search.placeholder')}
              onChange={handleQueryChange}
              className={classes.search}
            />
          </Box>
        }
      />
      <DialogContent data-loading={!!status.fetch?.loading}>
        <JoinedGroups
          title={query}
          onSelect={onSelect}
          onReset={handleReset}
          onLoadMore={handleLoadMore}
        />
      </DialogContent>
    </InputDialog>
  );

  function handleReset() {
    setQuery('');
  }

  function handleQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
  }

  function handleLoadMore() {
    if (!status.fetchMore?.loading) {
      queryGroups(query, groups.length);
    }
  }

  function queryGroups(query: string, cursor?: number) {
    groups$.query({
      limit: isMobile ? 15 : 10,
      title: query,
      offset: cursor,
      partition: EGroupPartition.JOINED,
      namespace: EGroupsNamespace.JOINED_DIALOG,
    });
  }
}
