import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';

import { Header } from '../Header';
import { PendingGroups } from '../../PendingGroups';

import { Groups } from './Groups';

export function GridLayout() {
  const { isMobile } = useEnvironment();

  if (isMobile) {
    return (
      <Box gap="SP0" direction="vertical">
        <Header />
        <PendingGroups />
        <Groups />
        {/* <CentralFeed />*/}
      </Box>
    );
  }

  return (
    <Box gap="SP6" direction="vertical">
      <Header />
      <PendingGroups />
      <Groups />
      {/* <Box padding="SP0 SP10" direction="vertical">
        <CentralFeed />
      </Box>*/}
    </Box>
  );
}

GridLayout.displayName = 'GridLayout';
