import React from 'react';
import cls from 'classnames';

import {
  CounterBadge as CounterBadgeTPA,
  CounterBadgePriority,
} from 'wix-ui-tpa';

import { classes } from './CounterBadge.st.css';

interface IProps
  extends Omit<React.ComponentProps<typeof CounterBadgeTPA>, 'priority'> {
  color: 'danger';
  secondary?: boolean;
}

export function CounterBadge(props: IProps) {
  const { className, color, secondary, ...rest } = props;

  return (
    <CounterBadgeTPA
      className={cls(classes.root, className, classes[color])}
      priority={
        secondary
          ? CounterBadgePriority.secondary
          : CounterBadgePriority.primary
      }
      {...rest}
    />
  );
}
