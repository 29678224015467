import React from 'react';
import { Avatar, AvatarSize, Checkbox } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';
import cls from 'classnames';

import type { IGroupMember, IMember } from 'api/members/types';

import { selectCurrentUser } from 'store/selectors';

import { UISref } from 'common/router';

import { Block, BlockFlow } from '../Block';
import { skeleton } from '../Skeleton';
import { MemberBadges } from '../MemberBadges';
import { PrivateProfileHint } from '../PrivateProfileHint';

import { CardProps } from './CardProps';
import { CardWrapper } from './CardWrapper';
import { CardText, CardTextProps } from './CardText';

import { classes, st } from './UserCard.st.css';

interface UserCardProps extends CardProps, CardTextProps {
  profile?: IMember | IGroupMember;
  thumbnail?: React.ReactNode;
  action?: React.ReactNode;
  avatarSize?: AvatarSize;

  showBadges?: boolean;
  selectable?: boolean;
  selected?: boolean;
  loading?: boolean;

  onSelect?(selected: boolean): void;
}

// TODO: driver
export const UserCard: React.FC<UserCardProps> = (props) => {
  const { profile } = props;
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const user = useSelector(selectCurrentUser);

  const isPrivate = profile?.isPrivate && profile?.memberId !== user?.memberId;

  return (
    <CardWrapper
      autoContent
      divider={props.divider}
      action={!props.loading && props.action}
      flow={isMobile ? BlockFlow.row : BlockFlow.col}
      data-name={profile?.name}
      className={st(
        classes.root,
        {
          bw: props.bw as boolean,
          withHover: !!props.onSelect,
          allowSelect: props.selectable as boolean,
        },
        props.className,
      )}
    >
      <Block onClick={handleClick} className={classes.userBlock}>
        {props.selectable ? (
          <Checkbox
            checked={props.selected}
            onChange={handleChange}
            className={classes.selectCheckbox}
          />
        ) : null}
        {(() => {
          if (props.thumbnail) {
            return props.thumbnail;
          }

          return (
            <PrivateProfileHint disabled={!isPrivate}>
              <UISref
                state="members.profile"
                disabled={!profile?.memberId}
                params={{ memberId: profile?.memberId }}
              >
                <a target="_blank" rel="noreferrer">
                  <Avatar
                    size={props.avatarSize}
                    src={!props.loading ? profile?.imageUrl : undefined}
                    className={cls(classes.avatar, {
                      [skeleton]: props.loading,
                    })}
                  />
                </a>
              </UISref>
            </PrivateProfileHint>
          );
        })()}
        <div>
          <PrivateProfileHint disabled={!isPrivate}>
            <UISref
              state="members.profile"
              disabled={!profile?.memberId}
              params={{ memberId: profile?.memberId }}
            >
              <a target="_blank" rel="noreferrer">
                <CardText
                  ellipsis
                  bw={props.bw}
                  subtitle={props.subtitle}
                  dataHook={props.dataHook}
                  reverse={props.reverse}
                  loading={props.loading}
                  title={
                    props.loading ? (
                      'Lorem ipsum dolor sit amet.'
                    ) : props.showBadges ? (
                      <>
                        {profile?.name || t('groups-web.member.anonymous')}
                        {props.showBadges ? (
                          <MemberBadges badges={profile?.badges} />
                        ) : null}
                      </>
                    ) : (
                      profile?.name || t('groups-web.member.anonymous')
                    )
                  }
                />
              </a>
            </UISref>
          </PrivateProfileHint>
          {props.children}
        </div>
      </Block>
    </CardWrapper>
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    props.onSelect?.(event.target.checked);
  }

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    if (props.onSelect) {
      event.preventDefault();
      props.onSelect(!props.selected);
    }
  }
};

UserCard.defaultProps = {
  showBadges: true,
};

UserCard.displayName = 'UserCard';
