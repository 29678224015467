import { curryRight, uniqBy } from 'lodash';
import { createSelector } from 'reselect';

import type { IUserReaction } from 'api/feed/types';
import { selectReactions, selectCurrentUser } from 'store/selectors';

export interface IReactionsMap {
  [code: string]: IUserReaction[];
}

export const selectHasReactions = curryRight(
  createSelector(selectReactions, (reactions) => !!reactions?.total),
  2,
);

export const selectReactedUsers = curryRight(
  createSelector(selectReactions, (reactions) => {
    const uniq = uniqBy(reactions?.reactions, 'user.siteMemberId');
    return uniq.map((reaction) => reaction.user);
  }),
  2,
);

export const selectReactionsMap = curryRight(
  createSelector(
    selectReactions,
    (reactions) =>
      reactions?.reactions.reduce((acc, reaction) => {
        return {
          ...acc,
          [reaction.code]: acc[reaction.code]?.concat([reaction]) ?? [reaction],
        };
      }, {} as IReactionsMap) ?? {},
  ),
  2,
);

export const selectUserReactions = curryRight(
  createSelector([selectReactions, selectCurrentUser], (reactions, user) =>
    reactions?.reactions.filter(
      (reaction) => reaction.user.siteMemberId === user.siteMemberId,
    ),
  ),
  2,
);
