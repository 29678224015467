import React from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';
import { Card } from 'wui/Card';
import { CardHeader } from 'wui/CardHeader';
import { CardContent } from 'wui/CardContent';
import { Skeleton } from 'wui/Skeleton';
import { Stack } from 'wui/Stack';
import { Hide } from 'wui/Hide';

export function FeedItemSkeleton() {
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const newReactions = experiments.enabled('specs.groups.NewReactions');

  return (
    <Card sideBorders={!isMobile}>
      <Box padding="SP5 0" gap="SP5" direction="vertical">
        <CardHeader
          title={<Skeleton width={isMobile ? '60%' : '30%'} />}
          subtitle={<Skeleton width={isMobile ? '50%' : '25%'} />}
          avatar={
            <Skeleton
              variant="circular"
              width={isMobile ? 36 : 52}
              height={isMobile ? 36 : 52}
            />
          }
        />
        <CardContent>
          <Skeleton variant="rect" height={200} />
        </CardContent>
        <Hide if={newReactions}>
          <CardContent>
            <Stack gap="SP2">
              <Skeleton variant="rounded" width={50} height={30} />
              <Skeleton variant="rounded" width={50} height={30} />
              <Skeleton variant="rounded" width={50} height={30} />
              <Hide if={isMobile}>
                <Skeleton variant="rounded" width={50} height={30} />
                <Skeleton variant="rounded" width={50} height={30} />
              </Hide>
            </Stack>
          </CardContent>
        </Hide>
      </Box>
    </Card>
  );
}

FeedItemSkeleton.displayName = 'FeedItemSkeleton';
