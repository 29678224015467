import React from 'react';
import cls from 'classnames';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';
import { Paper } from 'wui/Paper';

import { Groups } from './Groups';
import { Header } from '../Header';
import { PendingGroups } from '../../PendingGroups';

import { classes } from './ListLayout.st.css';

export function ListLayout() {
  const { isMobile } = useEnvironment();

  if (isMobile) {
    return (
      <Box gap="SP0" direction="vertical">
        <Header />
        <PendingGroups />
        <Groups />
        {/* <CentralFeed />*/}
      </Box>
    );
  }

  return (
    <Box gap="SP6" direction="vertical">
      <Header />
      <PendingGroups />
      <Paper>
        <Box
          direction="vertical"
          className={cls(classes.verticalSpacing, classes.horizontalSpacing)}
        >
          <Groups />
        </Box>
      </Paper>
      {/*
      <Box padding="SP0 SP10" direction="vertical">
        <CentralFeed />
      </Box>
      */}
    </Box>
  );
}

ListLayout.displayName = 'ListLayout';
