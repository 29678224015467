import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { TransitionGroup } from 'react-transition-group';
import { ChevronDown as ChevronDownIcon } from '@wix/wix-ui-icons-common/on-stage';

import {
  selectGroupRequests,
  selectGroupRequestsStatuses,
  selectIsSiteAdmin,
} from 'store/selectors';
import { useController } from 'common/context/controller';

import { TextButton, TextButtonPriority } from 'wix-ui-tpa';
import { Accordion, AccordionItem } from 'wui/Accordion';
import { List } from 'wui/List';
import { Divider } from 'wui/Divider';
import { Fade } from 'wui/Fade';
import { Show } from 'wui/Show';
import { CounterBadge } from 'wui/CounterBadge';

import { GroupRequest } from './GroupRequest';
import { PendingGroup, PendingGroupSkeleton } from './PendingGroup';
import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

const PLACEHOLDERS = new Array(5).fill(0).map((_, index) => (
  <Fade key={index}>
    <PendingGroupSkeleton />
  </Fade>
));

export function PendingGroups() {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();

  const { groupRequests$ } = useController();
  const isAdmin = useSelector(selectIsSiteAdmin);
  const { meta, requests } = useSelector(selectGroupRequests);
  const statuses = useSelector(selectGroupRequestsStatuses);

  useEffect(() => {
    if (!requests.length && meta.total) {
      handleLoadMore();
    }
  }, [meta.total, requests.length]);

  return (
    <Fade in={!!meta.total} mountOnEnter unmountOnExit>
      <Accordion onItemClick={reportBi}>
        <AccordionItem
          id="pending-groups"
          title={
            isAdmin
              ? t('groups-web.group-list.pending-groups.title')
              : t('groups-web.group-list.pending-groups.creator-title')
          }
          suffix={<CounterBadge color="danger" value={meta.total as number} />}
        >
          <List
            disablePadding
            separator={isMobile ? undefined : <Divider />}
            gap={isMobile ? 'SP2' : 'SP5'}
          >
            <TransitionGroup component={null}>
              {requests.map((request) => (
                <Fade key={request.id}>
                  {isAdmin ? (
                    <PendingGroup request={request} />
                  ) : (
                    <GroupRequest request={request} />
                  )}
                </Fade>
              ))}
              <Show if={statuses.fetch.loading || statuses.fetchMore.loading}>
                {PLACEHOLDERS}
              </Show>
            </TransitionGroup>
            <Show if={meta.total ? requests.length < meta.total : false}>
              <TextButton
                onClick={handleLoadMore}
                prefixIcon={<ChevronDownIcon />}
                priority={TextButtonPriority.primary}
              >
                {t('groups-web.showMore')}
              </TextButton>
            </Show>
          </List>
        </AccordionItem>
      </Accordion>
    </Fade>
  );

  function handleLoadMore() {
    groupRequests$.fetch({
      limit: 5,
      offset: requests.length,
    });
  }

  function reportBi(id: string, isExpanded: boolean) {
    bi.report(
      groupsLivesiteClick({
        screen_name: 'groups_requests',
        button_name: isExpanded ? 'expand_group_request' : 'hide_group_request',
      }),
    );
  }
}

PendingGroups.displayName = 'PendingGroups';
