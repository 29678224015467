import React from 'react';
import { useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import {
  ErrorMonitorBoundary,
  useEnvironment,
  useExperiments,
} from '@wix/yoshi-flow-editor';

import { IFeedItem } from 'api/feed/types';
import { selectFeed, selectFeedItems } from 'store/selectors';

import { FeedItem, FeedItemSkeleton } from 'common/components/FeedItem';
import { ErrorState } from 'wui/ErrorState';

import * as DATA_HOOKS from '../../dataHooks';

import { components } from './virtual';
import { computeItemKey, getItemContent } from './helpers';
import { useKeyboardScroll } from './useKeyboardScroll';
import { EmptyResults } from './EmptyResults';

import { st, classes } from './FeedItemList.st.css';

interface IFeedProps {
  showGroupName?: boolean;
  className?: string;

  slots?: {
    empty?: React.ReactElement;
  };

  onCreatePost(): void;
  onFetch(cursor?: string): void;
}

export function FeedItemList(props: IFeedProps) {
  const { onCreatePost, className, slots } = props;
  const { isSSR, isEditor, isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const state = useSelector(selectFeed);
  const items = useSelector(selectFeedItems);

  const keyboardScroller = useKeyboardScroll({
    size: items.length,
  });

  if (state.statuses.fetch.pending) {
    return (
      <div
        className={st(
          classes.root,
          { mobile: isMobile },
          className,
        )}
      >
        <div className={classes.list}>
          <FeedItemSkeleton />
          <FeedItemSkeleton />
          <FeedItemSkeleton />
        </div>
      </div>
    );
  }

  if (state.statuses.fetch.error) {
    return <ErrorState onRetry={handleRetry} />;
  }

  if (!items.length) {
    return slots?.empty || <EmptyResults onCreatePost={onCreatePost} />;
  }

  if (isEditor) {
    return (
      <div
        className={st(
          classes.root,
          { mobile: isMobile },
          className,
        )}
      >
        <div className={classes.list}>
          {items.map((item) => (
            <ErrorMonitorBoundary
              key={item.feedItemId}
              fallback={<ErrorState unhandled onRetry={handleRetry} />}
            >
              <FeedItem truncate item={item} promote={props.showGroupName} />
            </ErrorMonitorBoundary>
          ))}
        </div>
      </div>
    );
  }

  return (
    <ErrorMonitorBoundary
      fallback={<ErrorState unhandled onRetry={handleRetry} />}
    >
      <Virtuoso
        role="feed"
        aria-labelledby="feed-title"
        aria-busy={state.statuses.fetchMore.pending}
        ref={keyboardScroller.$virtuoso}
        data-hook={DATA_HOOKS.FEED}
        useWindowScroll
        data={items}
        endReached={fetchMore}
        components={components}
        itemContent={getItemContent}
        computeItemKey={computeItemKey}
        initialItemCount={isSSR ? items.length : undefined}
        defaultItemHeight={400}
        context={{
          onRetry: handleRetry,
          promote: props.showGroupName,
          focused: keyboardScroller.focused,
          focus: keyboardScroller.focus,
        }}
        className={st(
          classes.root,
          { mobile: isMobile },
          className,
        )}
      />
    </ErrorMonitorBoundary>
  );

  function handleRetry() {
    props.onFetch();
  }

  function fetchMore() {
    const { nextCursor, statuses } = state;

    if (
      nextCursor &&
      !statuses.fetchMore.pending &&
      !statuses.fetchMore.error
    ) {
      props.onFetch(nextCursor);
    }
  }
}

FeedItemList.displayName = 'FeedItemList';
