import React, { useEffect } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { useController } from 'common/context/controller';

import { Show } from 'wui/Show';
import { Popover } from 'wui/Popover';
import { Typography } from 'wui/Typography';
import { Stack } from 'wui/Stack';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa';
import { Tag as TagIcon } from '@wix/wix-ui-icons-common/on-stage';

import { Topic } from '../Topic';

import { TopicsForm } from './TopicsForm';

interface IProps extends React.ComponentProps<typeof TopicsForm> {}

export function TopicsSelect(props: IProps) {
  const { groupId, value } = props;

  const { t } = useTranslation();
  const { topics$ } = useController();

  const [isOpen, setIsOpen] = React.useState(false);

  const preview = value.slice(0, 2);
  const rest = value.slice(2);

  useEffect(() => {
    if (isOpen) {
      topics$.fetch(groupId);
    }
  }, [groupId, isOpen]);

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}
      minWidth={320}
      placement="top-start"
      content={<TopicsForm {...props} />}
    >
      {preview.length ? (
        <Stack wrap verticalAlign="middle" gap="SP2">
          <Typography as="span">
            <TagIcon />
          </Typography>
          {preview.map((topic) => (
            <Topic bw key={topic.id} onClick={handleOpen} topic={topic} />
          ))}
          <Show if={!!rest.length}>
            <Typography variant="p2-14" as="span">
              <TextButton
                onClick={handleOpen}
                priority={TextButtonPriority.secondary}
              >
                + {rest.length}
              </TextButton>
            </Typography>
          </Show>
        </Stack>
      ) : (
        <TextButton
          onClick={handleOpen}
          prefixIcon={<TagIcon />}
          priority={TextButtonPriority.secondary}
        >
          {t('groups-web.discussion.topics.modal.open-button')}
        </TextButton>
      )}
    </Popover>
  );

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }
}

TopicsSelect.displayName = 'TopicsSelect';
