import React from 'react';
import cls from 'classnames';

import { Modal } from '../Modal';
import { Direction, Slide } from '../Slide';
import { Paper } from '../Paper';

import { classes } from './Drawer.st.css';

interface IDrawerProps
  extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  bw?: boolean;
  anchor?: Direction;
  children?: React.ReactNode;

  paperProps?: React.ComponentProps<typeof Paper>;
}

export function Drawer(props: IDrawerProps) {
  const { anchor, bw, paperProps } = props;

  return (
    <Modal {...props}>
      <Slide appear direction={anchor} in={props.isOpen} timeout={300}>
        <Paper
          bw={bw}
          shadow={false}
          sideBorders={false}
          topBottomBorders={false}
          {...paperProps}
          className={cls(classes.paper, paperProps?.className, {
            [classes.left]: anchor === 'left',
            [classes.right]: anchor === 'right',
            [classes.top]: anchor === 'top',
            [classes.bottom]: anchor === 'bottom',
          })}
        >
          {props.children}
        </Paper>
      </Slide>
    </Modal>
  );
}

Drawer.displayName = 'Drawer';
Drawer.defaultProps = {
  anchor: 'bottom',
  bw: true,
};
