import React from 'react';

import { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { classes } from './ListItemAvatar.st.css';

interface IListItemAvatarProps extends TPAComponentProps {
  children: React.ReactNode;
}

export function ListItemAvatar(props: IListItemAvatarProps) {
  return <div className={classes.root}>{props.children}</div>;
}
