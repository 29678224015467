import React from 'react';
import cls from 'classnames';

import { Spinner as TPASpinner, SpinnerTypes } from 'wix-ui-tpa';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { classes } from './Spinner.st.css';

interface IProps extends TPAComponentProps {
  bw?: boolean;
  size?: number;
}

export function Spinner(props: IProps) {
  const { className, bw, size, ...rest } = props;

  return (
    <TPASpinner
      diameter={size}
      type={SpinnerTypes.slim}
      className={cls(classes.root, className, {
        [classes.bw]: bw,
      })}
      {...rest}
    />
  );
}

Spinner.displayName = 'Spinner';
