import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Accordion } from 'wix-ui-tpa';

import { classes } from './AccordionItem.st.css';

interface IAccordionItemProps
  extends React.ComponentProps<typeof Accordion.Item> {
  children: React.ReactNode;

  disablePadding?: boolean;
}

export function AccordionItem(props: IAccordionItemProps) {
  const { children, disablePadding, className, ...rest } = props;

  const { isMobile } = useEnvironment();

  return (
    <Accordion.Item
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
        [classes.disablePadding]: disablePadding,
        [classes.hideSuffix]: !props.suffix,
      })}
      {...rest}
    >
      <div onMouseDown={handleMouseDown}>{children}</div>
    </Accordion.Item>
  );

  /**
   * Prevent event propagation to the Accordion.Item mouseDown handler.
   * https://github.com/wix-private/wix-design-systems/blob/master/packages/wix-ui-tpa/src/components/Accordion/Accordion.tsx#L187
   */
  function handleMouseDown(event: React.MouseEvent) {
    event.stopPropagation();
  }
}

AccordionItem.displayName = 'AccordionItem';
