import React from 'react';
import { useSelector } from 'react-redux';

import { EditorEventsProvider } from 'wix-rich-content-editor-common/dist/lib/EditorEventsContext';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import {
  groupCreatePostClick,
  groupFeedView,
  groupInviteMembersOptionClicked,
} from '@wix/bi-logger-groups/v2';

import { BIUserEntry } from 'common/bi-logger/types';
import { useController } from 'common/context/controller';
import { useShadowStyles } from 'common/hooks/useShadowStyles';
import { EmptyState } from 'wui/EmptyState';

import { EGroupsNamespace } from 'store/groups/constants';
import { selectGroups, selectIsSiteAdmin } from 'store/selectors';

import { FeedItemEditorDialog } from 'common/components/FeedItemEditorDialog';
import { FeedItemList } from 'Group/Widget/DiscussionPage/GroupFeed/FeedItemList';
import { CreatePost } from 'Group/Widget/DiscussionPage/GroupFeed/CreatePost';
import { InviteMembersModal } from 'Group/Widget/Header/InviteMembers/InviteMembersModal';

import settingsParams from '../../settingsParams';

import { InviteMembersCard } from './InviteMembersCard';
import { SelectGroupModal } from './SelectGroupModal';

import { selectShouldShowInviteMembers } from './selectors';

import { st, classes } from './CentralFeed.st.css';

type CentralFeedProps = {};

type DialogsState = {
  createPost: boolean;
  selectGroup: boolean;
  inviteMembers: boolean;
};

export const CentralFeed: React.FC<CentralFeedProps> = () => {
  const { t } = useTranslation();
  const bi = useBi();

  // TODO: remove after migration CentralFeed to wui components
  const shadowStyles = useShadowStyles();

  const { centralFeed$, feed$ } = useController();

  const settings = useSettings();
  const { isMobile } = useEnvironment();

  const [selected, setSelected] = React.useState<string>();
  const actionForGroup = React.useRef<() => void>();

  const [dialogs, _setDialogs] = React.useState<DialogsState>({
    createPost: false,
    selectGroup: false,
    inviteMembers: false,
  });

  const prevDialogs = React.useRef<DialogsState>({
    createPost: false,
    selectGroup: false,
    inviteMembers: false,
  });

  const isSiteAdmin = useSelector(selectIsSiteAdmin);
  const showInviteMembersCard = useSelector(selectShouldShowInviteMembers);
  const { groups, meta } = useSelector(selectGroups(EGroupsNamespace.JOINED));

  const showCreatePost = settings.get(settingsParams.showCreatePost);

  React.useEffect(() => {
    bi.report(
      groupFeedView({
        container: 'groups',
        roles: isSiteAdmin ? 'admin' : 'member',
        userEntry: BIUserEntry.SITE,
      }),
    );
  }, []);

  return (
    <div className={st(classes.root)} ref={shadowStyles.onRefChange}>
      {showCreatePost ? (
        <CreatePost
          onClick={handleActionForGroup(openCreatePostDialog)}
          className={classes.createPost}
        />
      ) : null}
      <FeedItemList
        showGroupName
        className={classes.feedItemList}
        onCreatePost={handleActionForGroup(openCreatePostDialog)}
        onFetch={handleFetchMore}
        slots={{
          empty: (
            <EmptyState
              title={t('groups-web.no-posts.title')}
              subtitle={t('groups-web.no-posts.content')}
            />
          ),
        }}
      />
      {showInviteMembersCard ? (
        <InviteMembersCard
          onClick={handleActionForGroup(openInviteMembersDialog)}
        />
      ) : null}
      <SelectGroupModal
        isOpen={dialogs.selectGroup}
        onClose={closeDialogs}
        onSelect={handleGroupSelect}
      />
      <InviteMembersModal
        isOpen={dialogs.inviteMembers}
        groupId={selected as string}
        onClose={closeDialogs}
      />
      {selected ? (
        <EditorEventsProvider>
          <FeedItemEditorDialog
            promoteGroup
            groupId={selected}
            onClose={closeDialogs}
            isOpen={dialogs.createPost}
            onCancel={handleBackButtonClick}
            cancelLabel={t('groups-web.discussion.new-post.back')}
          />
        </EditorEventsProvider>
      ) : null}
    </div>
  );

  function handleFetchMore(cursor?: string) {
    centralFeed$.fetch(cursor);
  }

  function handleActionForGroup(cb: () => void) {
    return () => {
      actionForGroup.current = cb;

      if (meta && meta.total === 1) {
        setSelected(groups[0].id as string);
        cb();
      } else {
        openSelectGroupDialog();
      }
    };
  }

  function openCreatePostDialog() {
    bi.report(
      groupCreatePostClick({
        origin: 'new_layout_groups_sidebar',
        userEntry: BIUserEntry.SITE,
      }),
    );

    setDialogs({
      createPost: true,
      selectGroup: false,
      inviteMembers: false,
    });
  }

  function openInviteMembersDialog() {
    bi.report(
      groupInviteMembersOptionClicked({
        origin: 'invite_members_sidebar',
        optionType: 'invite',
        userEntry: BIUserEntry.SITE,
      }),
    );
    setDialogs({
      createPost: false,
      selectGroup: false,
      inviteMembers: true,
    });
  }

  function openSelectGroupDialog() {
    setDialogs({
      createPost: false,
      selectGroup: true,
      inviteMembers: false,
    });
  }

  function closeDialogs() {
    setDialogs({
      createPost: false,
      selectGroup: false,
      inviteMembers: false,
    });
  }

  function handleGroupSelect(groupId: string) {
    setSelected(groupId);
    actionForGroup?.current?.();
  }

  function handleBackButtonClick() {
    setDialogs(prevDialogs.current);
  }

  function setDialogs(nextDialogs: DialogsState) {
    _setDialogs((dialogs) => {
      prevDialogs.current = dialogs;
      return nextDialogs;
    });
  }
};
