import React from 'react';
import cls from 'classnames';

import {
  Checkbox as TPACheckbox,
  CheckboxProps as TPACheckboxProps,
} from 'wix-ui-tpa';

import { classes } from './Checkbox.st.css';

export interface CheckboxProps
  extends Omit<TPACheckboxProps, 'onChange' | 'label'> {
  bw?: boolean;
  onChange?: TPACheckboxProps['onChange'];
  label?: TPACheckboxProps['label'];
}

export const Checkbox: React.FC<CheckboxProps> = ({
  bw,
  className,
  ...restProps
}) => {
  return (
    <TPACheckbox
      {...restProps}
      onChange={restProps.onChange || noop}
      label={restProps.label || ''}
      className={cls(classes.root, className, {
        [classes.bw]: bw,
      })}
    />
  );
};

function noop() {}
