import React from 'react';
import { useBi, useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';

import { useSettings } from '@wix/tpa-settings/react';
import { groupsSettingsParams } from 'settings/groups';

import { groupListLoaded } from '@wix/bi-logger-groups/v2';

import { SocialGroupsApp } from 'common/components/SocialGroupsApp';
import { groupsScreenMap } from 'common/hooks/useBiParams';

import { COMPONENT } from 'settings/consts';

import { Container } from 'wui/Container';

import type { IVMProps } from '../../../vm/types';

import { Layout } from './Layout';
import { CreateGroupDialog } from './CreateGroupDialog';

import { classes } from '../styles.st.css';

COMPONENT.name = 'GroupsPage';

const Widget: React.FC<WidgetProps<IVMProps>> = (props) => {
  const { isMobile } = useEnvironment();
  const bi = useBi();
  const settings = useSettings();

  React.useEffect(() => {
    bi.report(
      groupListLoaded({
        layout_type:
          groupsScreenMap[settings.get(groupsSettingsParams.groupListLayout)],
      }),
    );
  }, []);

  return (
    <SocialGroupsApp {...props}>
      <Container
        fluid={isMobile}
        data-hook="Groups-wrapper"
        className={classes.appBackground}
      >
        <Layout />
        <CreateGroupDialog />
      </Container>
    </SocialGroupsApp>
  );
};

Widget.displayName = 'GroupListPage';

export default Widget;
