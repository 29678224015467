import React from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import {
  groupsRequestJoinAGroup,
  groupsSelectGroupFromList,
} from '@wix/bi-logger-groups/v2';

import { ImageResizeOptions, TextButton, TextButtonPriority } from 'wix-ui-tpa';

import { useSettings } from '@wix/tpa-settings/react';
import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';
import { groupsSettingsParams as settingsParams } from 'settings/groups/settings';
import { ImageRatio } from 'settings/consts';

import type { IGroup } from 'store/groups';

import { ListItem } from 'wui/ListItem';
import { ButtonSize } from 'wui/Button';
import { ListItemText } from 'wui/ListItemText';
import { ListItemAction } from 'wui/ListItemAction';
import { Show } from 'wui/Show';

import { UISref } from 'common/router';
import { GroupInfo } from 'common/components/GroupInfo';
import { GroupMembershipButton } from 'common/components/GroupMembership';
import { BIUserEntry } from 'common/bi-logger/types';
import { GroupImage } from 'common/components/GroupImage';

import { ListItemIcon } from 'wui/ListItemIcon';
import { Box } from 'wui/Box';

interface IGroupListItemProps {
  group: IGroup;
}

export function SuggestedGroupListItem(props: IGroupListItemProps) {
  const { group } = props;

  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const settings = useSettings();

  return (
    <ListItem disablePadding actionOnHover={!isMobile}>
      <Show if={settings.get(settingsParams.showImage)}>
        <ListItemIcon>
          <Box width={40} height={40}>
            <GroupImage
              width={40}
              height={40}
              image={group.coverImage?.image}
              resize={ImageResizeOptions.cover}
              aspectRatio={ImageRatio.square}
            />
          </Box>
        </ListItemIcon>
      </Show>
      <ListItemText
        title={
          <UISref
            state="group"
            params={{ slug: group.slug }}
            bi={groupsSelectGroupFromList({
              groupId: group.id as string,
              origin: 'new_layout_groups_sidebar_suggested_groups',
              userEntry: BIUserEntry.SITE,
            })}
          >
            <TextButton as="a" priority={TextButtonPriority.secondary}>
              {group.name}
            </TextButton>
          </UISref>
        }
        subtitle={<GroupInfo bw={isMobile} groupId={group.id as string} />}
        titleProps={{ noWrap: true }}
        subtitleProps={{ noWrap: true }}
      />
      <Show if={settings.get(groupsListWidgetSettingsParams.showButton)}>
        <ListItemAction>
          <GroupMembershipButton
            upgrade={!isMobile}
            size={isMobile ? ButtonSize.tiny : undefined}
            groupId={group.id as string}
            bi={groupsRequestJoinAGroup({
              group_id: group.id as string,
              origin: 'new_layout_groups_sidebar_suggested_groups',
              userEntry: BIUserEntry.SITE,
            })}
          />
        </ListItemAction>
      </Show>
    </ListItem>
  );
}

SuggestedGroupListItem.displayName = 'SuggestedGroupListItem';
