import * as React from 'react';
import cls from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import { classes } from './Slide.st.css';

export type Direction = 'left' | 'right' | 'top' | 'bottom';

interface ISlideProps {
  children: React.ReactNode;

  direction?: Direction;
}

export function Slide(
  props: ISlideProps & React.ComponentProps<typeof CSSTransition>,
) {
  const { children, direction, ...rest } = props;

  return (
    <CSSTransition classNames={classes as CSSTransitionClassNames} {...rest}>
      {React.cloneElement(children as React.ReactElement, {
        className: cls(
          classes[direction as string],
          (children as React.ReactElement)?.props?.className,
        ),
      })}
    </CSSTransition>
  );
}

Slide.displayName = 'Slide';
Slide.defaultProps = {
  direction: 'left',
};
