import React from 'react';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useSelector } from 'react-redux';

import { debounce } from 'lodash';

import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';
import { EGroupPartition } from 'api/groups/types';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Paper } from 'wui/Paper';
import { Search } from 'wui/Search';
import { Typography } from 'wui/Typography';
import {
  DropdownOptionProps,
  FloatingDropdown,
  TextButton,
  TextButtonPriority,
  TextField,
  IconButton,
} from 'wix-ui-tpa';

import { useCurrentStateAndParams, useRouter } from 'common/router';
import { useController } from 'common/context/controller';

import { groupsSettingsParams, groupsStylesParams } from 'settings/groups';
import { GroupsRequestSort } from 'settings/consts';

import {
  selectCanCreateGroup,
  selectCanCreateGroupRequests,
} from 'store/selectors';

import { HEADER_CREATE_GROUP_BUTTON } from './dataHooks';

import { classes } from './Header.st.css';
import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';
import { useBiParams } from 'common/hooks/useBiParams';

const TRANSLATION_KEYS = {
  [GroupsRequestSort.NAME]: 'groups-web.group-list.sort.alphabetical',
  [GroupsRequestSort.MEMBERS_COUNT]: 'groups-web.group-list.sort.member-count',
  [GroupsRequestSort.RECENT_ACTIVITY]:
    'groups-web.group-list.sort.recent-activity',
};

export function Header() {
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const bi = useBi();
  const biParams = useBiParams();

  const router = useRouter();
  const { params } = useCurrentStateAndParams();
  const { application$ } = useController();

  const [query, setQuery] = React.useState('');
  const delayedQuery = React.useCallback(debounce(searchGroups, 500), []);

  React.useEffect(() => {
    setQuery(params.title || '');
  }, [params.title || '']);

  const canCreateGroup = useSelector(selectCanCreateGroup);
  const canCreateGroupRequests = useSelector(selectCanCreateGroupRequests);

  const showCreateGroupButton = canCreateGroup || canCreateGroupRequests;

  const { htmlTag } = styles.get(groupsStylesParams.listTitleFont);
  const showSearchSort = settings.get(groupsSettingsParams.showSearchSorting);

  if (isMobile) {
    return (
      <Box direction="vertical" gap="SP0">
        <Box align="space-between" padding="SP5">
          <Show if={settings.get(groupsSettingsParams.showHeaderTitle)}>
            <Typography
              as={htmlTag}
              variant="h2-20"
              data-hook="group-list-title"
              className={classes.title}
            >
              {settings.get(groupsSettingsParams.groupListTitle)}
            </Typography>
          </Show>
          <Show if={showCreateGroupButton}>
            <TextButton
              onClick={openCreateGroupDialog}
              priority={TextButtonPriority.primary}
              data-hook={HEADER_CREATE_GROUP_BUTTON}
            >
              {t('groups-web.btn.create-new')}
            </TextButton>
          </Show>
        </Box>
        <Show if={showSearchSort}>
          <Paper sideBorders={false}>
            <Box direction="vertical" padding="SP5">
              <TextField
                className={classes.mobileSearch}
                prefix={<SearchIcon />}
                value={query}
                placeholder={t('groups-web.search.placeholder')}
                onChange={(evt) => handleQueryChange(evt.target?.value)}
              />
            </Box>
          </Paper>
        </Show>
      </Box>
    );
  }

  return (
    <Box align="space-between">
      <Box gap="SP6" verticalAlign="middle">
        <Show if={settings.get(groupsSettingsParams.showHeaderTitle)}>
          <Typography
            as={htmlTag}
            variant="h2-20"
            data-hook="group-list-title"
            className={classes.title}
          >
            {settings.get(groupsSettingsParams.groupListTitle)}
          </Typography>
        </Show>
        <Show if={showSearchSort}>
          <Box gap="SP2">
            {/* @Andrii Soboliev would like to redesign, temporary disable the feature
            <IconButton
              className={classes.sortIcon}
              icon={<SortIcon />}
              onClick={handleOrderChange}
            />
            */}
            <FloatingDropdown
              className={classes.sortDropdown}
              onChange={handleSortChange}
              label={t('groups-web.group-list.sort') + ':'}
              placeholder={t('groups-web.members.filter.all')}
              value={params.sort}
              options={[
                {
                  isSelectable: true,
                  id: GroupsRequestSort.RECENT_ACTIVITY,
                  value: t(TRANSLATION_KEYS[GroupsRequestSort.RECENT_ACTIVITY]),
                },
                {
                  isSelectable: true,
                  id: GroupsRequestSort.NAME,
                  value: t(TRANSLATION_KEYS[GroupsRequestSort.NAME]),
                },
                {
                  isSelectable: true,
                  id: GroupsRequestSort.MEMBERS_COUNT,
                  value: t(TRANSLATION_KEYS[GroupsRequestSort.MEMBERS_COUNT]),
                },
              ]}
            />
          </Box>
        </Show>
      </Box>
      <Box gap="SP6" verticalAlign="middle">
        <Show if={showSearchSort}>
          <Search
            className={classes.search}
            withCloseButton
            forceOpen={!!params.title}
            value={query}
            onChange={(evt) => handleQueryChange(evt?.target?.value)}
            placeholder={t('groups-web.search.placeholder')}
            onClose={() => handleQueryChange('')}
          />
        </Show>
        <Show if={showCreateGroupButton}>
          <TextButton
            data-hook={HEADER_CREATE_GROUP_BUTTON}
            onClick={openCreateGroupDialog}
            priority={TextButtonPriority.primary}
          >
            {t('groups-web.btn.create-new')}
          </TextButton>
        </Show>
      </Box>
    </Box>
  );

  // function handleOrderChange() {
  //   router.go('.', { order: params.order === 'ASC' ? 'DESC' : 'ASC' });
  // }

  function openCreateGroupDialog() {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreen,
        button_name: 'create_group',
      }),
    );
    application$.showDialog({ dialog: 'createGroup' });
  }

  function handleSortChange(option: DropdownOptionProps) {
    if (option.id === params.sort) {
      return;
    }

    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreen,
        button_name: 'sort_by',
      }),
    );

    router.go('.', { sort: option.id });
  }

  function handleQueryChange(query: string) {
    setQuery(query);
    delayedQuery(query);
  }

  function searchGroups(value: string) {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreen,
        button_name: 'search',
      }),
    );
    router.go('.', {
      title: value,
      partition: value ? EGroupPartition.ALL : params.partition,
    });
  }
}

Header.displayName = 'Header';
