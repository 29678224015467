import React from 'react';

import { IFeedItem, FeedItemActivityType } from 'api/feed/types';

import { DraftJSActivity } from './DraftJSActivity';
import { CoverImageChanged } from './CoverImageChanged';
import { DescriptionChanged } from './DescriptionChanged';

interface IProps {
  item: IFeedItem;
}

export function ActivityContent(props: IProps) {
  const { item } = props;

  const { activity } = item;

  if (!activity) {
    return null;
  }

  switch (activity.activityType) {
    case FeedItemActivityType.ABOUT_GROUP_CHANGED:
      return <DescriptionChanged item={item} />;

    case FeedItemActivityType.GROUP_COVER_CHANGED:
      return <CoverImageChanged item={item} />;

    case FeedItemActivityType.DRAFT_JS:
      return <DraftJSActivity item={item} />;

    default:
      return null;
  }
}

ActivityContent.displayName = 'ActivityContent';
