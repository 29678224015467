import React from 'react';

import { Box } from 'wui/Box';

interface IProps extends React.ComponentProps<typeof Box> {}

export function DialogMobileHeader(props: IProps) {
  return (
    <Box
      padding="SP0 SP5"
      direction="horizontal"
      align="space-between"
      {...props}
    />
  );
}

DialogMobileHeader.displayName = 'DialogMobileHeader';
