import React, { HTMLAttributeAnchorTarget } from 'react';

import { UISref } from 'common/router';

import { TextButton, TextButtonProps, TextButtonPriority } from 'wix-ui-tpa';

interface IProps
  extends TextButtonProps,
    Omit<React.ComponentProps<typeof UISref>, 'children'> {
  target?: HTMLAttributeAnchorTarget | undefined;

  secondary?: boolean;
  srefProps?: Partial<React.ComponentProps<typeof UISref>>;
}

export function Link(props: IProps) {
  const { secondary, state, params, options, bi, ...rest } = props;

  return (
    <UISref
      bi={bi}
      state={state}
      params={params}
      options={options}
      disabled={props.disabled}
    >
      <TextButton
        as="a"
        rel="noreferrer"
        priority={
          secondary ? TextButtonPriority.secondary : TextButtonPriority.primary
        }
        {...rest}
      />
    </UISref>
  );
}

Link.displayName = 'Link';
Link.defaultProps = { secondary: true };
