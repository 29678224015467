import React from 'react';
import cls from 'classnames';
import { Tag, TagSize, TagSkin } from 'wix-ui-tpa';

import type { ITopic } from 'api/topics/types';

import { classes } from './Topic.st.css';

interface ITopicProps {
  bw?: boolean;
  active?: boolean;
  isRemovable?: boolean;

  topic: ITopic;
  withCounter?: boolean;

  onClick?: (topic: ITopic) => void;
  onRemove?: (topic: ITopic) => void;
}

export function Topic(props: ITopicProps) {
  const { topic, active, bw, isRemovable, withCounter } = props;

  return (
    <Tag
      size={TagSize.small}
      isRemovable={isRemovable}
      skin={active ? TagSkin.solid : TagSkin.light}
      onClick={props.onClick ? handleClick : undefined}
      onRemove={props.onRemove ? handleRemove : undefined}
      className={cls(classes.root, {
        [classes.bw]: bw,
        [classes.active]: active,
      })}
    >
      {!withCounter
        ? topic.displayName
        : `${topic.displayName} (${topic.count})`}
    </Tag>
  );

  function handleClick() {
    props.onClick?.(topic);
  }

  function handleRemove() {
    props.onRemove?.(topic);
  }
}
