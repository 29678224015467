import React from 'react';
import { useSelector } from 'react-redux';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { Trans, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import type { IGroup } from 'store/groups/types';
import { selectDashboardUrl, selectIsSiteAdmin } from 'store/selectors';
import { useFileUploader } from 'common/hooks';

import { Hide } from 'wui/Hide';
import { Box } from 'wui/Box';
import { Typography } from 'wui/Typography';
import { TextField } from 'wui/TextField';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa';

import { GroupPrivacySelect } from '../GroupPrivacySelect';
import { CoverImageEditor, IEditableCoverImage } from './CoverImageEditor';

import { EDIT_GROUP_FORM_TITLE_INPUT } from './dataHooks';

export interface IEditableGroup extends Pick<IGroup, 'name' | 'privacyStatus'> {
  coverImage?: IEditableCoverImage;
}

export interface IProps
  extends React.ComponentProps<typeof Formik<IEditableGroup>> {
  onBusyStateChange?(busy: boolean): void;
}

export function EditGroupForm(props: IProps) {
  const { onBusyStateChange, onSubmit, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const uploader = useFileUploader();

  const isSiteAdmin = useSelector(selectIsSiteAdmin);
  const dashboardUrl = useSelector(selectDashboardUrl);

  return (
    <Formik onSubmit={handleSubmit} validate={handleValidate} {...rest}>
      {(form) => (
        <Box direction="vertical" gap="SP4">
          <CoverImageEditor
            {...form.getFieldProps('coverImage')}
            onChange={(coverImage) =>
              form.setFieldValue('coverImage', coverImage)
            }
          />
          <TextField
            bw
            required
            maxLength={50}
            errorMessage={form.errors.name}
            error={!!form.errors.name && form.touched.name}
            label={t('groups-web.modals.create-groups.groupName')}
            placeholder={t(
              'groups-web.modals.create-groups.groupName.placeholder',
            )}
            data-hook={EDIT_GROUP_FORM_TITLE_INPUT}
            {...form.getFieldProps('name')}
          />
          <GroupPrivacySelect
            {...form.getFieldProps('privacyStatus')}
            onChange={(privacy) => form.setFieldValue('privacyStatus', privacy)}
          />
          <Hide if={isMobile || !isSiteAdmin}>
            <Typography>
              <Trans
                i18nKey="groups-web.create-group-dialog.manage-dashboard"
                components={[
                  <TextButton
                    as="a"
                    href={dashboardUrl}
                    priority={TextButtonPriority.primary}
                  >
                    Dashboard
                  </TextButton>,
                ]}
              />
            </Typography>
          </Hide>
        </Box>
      )}
    </Formik>
  );

  async function handleSubmit(
    values: IEditableGroup,
    helpers: FormikHelpers<IEditableGroup>,
  ) {
    if (!props.onSubmit) {
      return;
    }

    const { file } = values.coverImage || {};

    if (!file) {
      props.onSubmit(values, helpers);
      return;
    }

    try {
      onBusyStateChange?.(true);

      const { file_name: mediaId, height, width } = await uploader.upload(file);

      props.onSubmit(
        {
          name: values.name,
          privacyStatus: values.privacyStatus,
          coverImage: {
            image: {
              mediaId,
              height,
              width,
            },
          },
        },
        helpers,
      );
    } catch {
      props.onSubmit(values, helpers);
    } finally {
      onBusyStateChange?.(false);
    }
  }

  function handleValidate(values: IEditableGroup) {
    const errors: FormikErrors<IEditableGroup> = {};

    if (!values.name?.trim()) {
      errors.name = t('groups-web.create-group.error.name.required');
    }

    return errors;
  }
}

EditGroupForm.displayName = 'EditGroupForm';
