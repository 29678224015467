import React from 'react';
import classnames from 'classnames';
import { Text, TextTypography } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import {
  Video as VideoIcon,
  Camera as CameraIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import { FileInput } from 'common/components/FileInput/FileInput';

import { GiphyPluginIcon } from 'Group/Widget/icons/GiphyPluginIcon';

import {
  INSERT_PLUGIN_BUTTONS,
  EPluginButton,
} from 'Group/Widget/RichContent/types';

import { st, classes } from './PluginsToolbar.st.css';

export interface IPluginsToolbarProps {
  className?: string;

  onClick?(button: EPluginButton, event: React.MouseEvent | File[]): void;
}

export function PluginsToolbar(props: IPluginsToolbarProps) {
  const { t } = useTranslation();

  const defaultClassName = classnames(
    classes.fillPluginIcon,
    classes.pluginIcon,
  );

  const imagePlugin = (
    <>
      <CameraIcon className={defaultClassName} />
      <Text
        typography={TextTypography.runningText}
        className={classes.pluginIconName}
      >
        {t('groups-web.discussion.new-post.plugins.image')}
      </Text>
    </>
  );

  return (
    <div className={st(classes.root, {}, props.className)}>
      {props.onClick ? (
        <FileInput
          className={classes.pluginIconWrapper}
          onChange={handleClick(INSERT_PLUGIN_BUTTONS.IMAGE)}
        >
          {imagePlugin}
        </FileInput>
      ) : (
        <div className={classes.pluginIconWrapper}>{imagePlugin}</div>
      )}
      <button
        className={classes.pluginIconWrapper}
        onClick={handleClick(INSERT_PLUGIN_BUTTONS.VIDEO)}
      >
        <VideoIcon className={defaultClassName} />
        <Text
          typography={TextTypography.runningText}
          className={classes.pluginIconName}
        >
          {t('groups-web.discussion.new-post.plugins.video')}
        </Text>
      </button>
      <button
        className={classes.pluginIconWrapper}
        onClick={handleClick(INSERT_PLUGIN_BUTTONS.GIF)}
      >
        <GiphyPluginIcon
          width={19}
          height={19}
          className={classnames(classes.strokePluginIcon, classes.pluginIcon)}
          key="Giphy"
        />
      </button>
    </div>
  );

  function handleClick(button: EPluginButton) {
    return (event: React.MouseEvent | File[]) => {
      props.onClick?.(button, event);
    };
  }
}

PluginsToolbar.displayName = 'PluginsToolbar';
