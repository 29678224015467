import React, { useEffect, useRef } from 'react';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';

import { selectCanCreateGroup, selectDialog } from 'store/selectors';

import { Button } from 'wui/Button';
import { DialogActions } from 'wui/DialogActions';
import { DialogContent } from 'wui/DialogContent';
import { DialogTitle } from 'wui/DialogTitle';
import { InputDialog } from 'wui/InputDialog';

import { EditGroupForm, IEditableGroup } from 'common/components/EditGroupForm';
import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { selectCreationStatus } from './selectors';
import {
  CREATE_GROUP_DIALOG_ROOT,
  CREATE_GROUP_DIALOG_SUBMIT_BUTTON,
} from './dataHooks';

interface ICreateGroupDialogProps
  extends Partial<React.ComponentProps<typeof InputDialog>> {}

export function CreateGroupDialog(props: ICreateGroupDialogProps) {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const status = useSelector(selectCreationStatus);
  const canCreateGroup = useSelector(selectCanCreateGroup);
  const { isOpen } = useSelector(selectDialog('createGroup'));

  const { application$, groups$, groupRequests$ } = useController();

  const form = useRef<FormikProps<IEditableGroup>>(null);
  const [isBusy, setIsBusy] = React.useState(false);

  useDidUpdate(() => {
    if (!status.loading && !status.error) {
      handleClose();
    }
  }, [status.loading]);

  useEffect(() => {
    if (!isOpen) {
      form.current?.resetForm();
    }
  }, [isOpen]);

  return (
    <InputDialog
      isOpen={isOpen}
      fullscreen={isMobile}
      onClose={handleClose}
      paperProps={{ 'data-hook': CREATE_GROUP_DIALOG_ROOT }}
      {...props}
    >
      <DialogTitle title={t('groups-web.modals.create-group.title')} />
      <DialogContent divider>
        <EditGroupForm
          innerRef={form}
          onSubmit={handleFormSubmit}
          onBusyStateChange={setIsBusy}
          initialValues={{
            name: '',
            coverImage: undefined,
            privacyStatus: PrivacyStatus.PUBLIC,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button bw secondary fullWidth={isMobile} onClick={handleClose}>
          {t('groups-web.btn.cancel')}
        </Button>
        <Button
          bw
          fullWidth={isMobile}
          onClick={handleSubmit}
          disabled={isBusy || status.loading}
          loading={isBusy || status.loading}
          data-hook={CREATE_GROUP_DIALOG_SUBMIT_BUTTON}
        >
          {t('groups-web.btn.create')}
        </Button>
      </DialogActions>
    </InputDialog>
  );

  function handleSubmit() {
    form.current?.submitForm();
  }

  function handleClose() {
    application$.closeDialog('createGroup');
  }

  function handleFormSubmit(values: IEditableGroup) {
    if (canCreateGroup) {
      groups$.create(values);
    } else {
      groupRequests$.create(values);
    }
  }
}
