import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupsRequestJoinAGroup } from '@wix/bi-logger-groups/v2';

import { selectGroupPrivacyStatus } from 'store/selectors';
import type { IFeedItem } from 'api/feed/types';

import {
  Pin as PinIcon,
  Info as InfoIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import { CardContent } from 'wui/CardContent';
import { Typography } from 'wui/Typography';
import { Box } from 'wui/Box';
import { BIUserEntry } from 'common/bi-logger/types';

import { GroupMembershipButton } from '../GroupMembership';

import { getAriaId } from './a11y';
import { classes } from './FeedItem.st.css';

interface IProps {
  item: IFeedItem;
  variant: 'pinned' | 'suggested';
}

export function FeedItemDisclaimer(props: IProps) {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const feedItemId = props.item.feedItemId as string;
  const groupId = props.item.applicationContext?.contextId as string;
  const privacyStatus = useSelector(selectGroupPrivacyStatus(groupId));

  switch (props.variant) {
    case 'pinned':
      return (
        <CardContent
          as="div"
          gap="SP2"
          verticalAlign="middle"
          direction="horizontal"
          id={getAriaId(feedItemId, 'disclaimer')}
        >
          <PinIcon
            size={isMobile ? 20 : 24}
            className={classes.diclaimerIcon}
          />
          <Typography className={classes.disclaimerText}>
            {t('groups-web.discussion.feed.pined-post')}
          </Typography>
        </CardContent>
      );

    case 'suggested':
      return (
        <CardContent
          as="div"
          align="space-between"
          direction="horizontal"
          verticalAlign="middle"
          id={getAriaId(feedItemId, 'disclaimer')}
        >
          <Box gap="SP2" verticalAlign="middle">
            <InfoIcon
              size={isMobile ? 20 : 24}
              className={classes.diclaimerIcon}
            />
            <Typography className={classes.disclaimerText}>
              {t('groups-web.discussion.feed.suggested-group.post-label.info')}
            </Typography>
          </Box>

          <GroupMembershipButton
            secondary
            groupId={groupId}
            bi={groupsRequestJoinAGroup({
              group_id: groupId,
              origin: 'public_feed',
              userEntry: BIUserEntry.SITE,
              type: (privacyStatus || '').toLowerCase(),
            })}
          />
        </CardContent>
      );

    default:
      return null;
  }
}

FeedItemDisclaimer.displayName = 'FeedItemDisclaimer';
