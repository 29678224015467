import React from 'react';
import cls from 'classnames';

import {
  Tabs as TPATabs,
  TabsAlignment,
  TabsSkin,
  TabsVariant,
} from 'wix-ui-tpa';

import { classes } from './Tabs.st.css';

interface ITabsProps
  extends Omit<
    React.ComponentProps<typeof TPATabs>,
    'activeTabIndex' | 'onTabClick'
  > {
  bw?: boolean;
  value: string;

  onChange: (id: string) => void;
}

export function Tabs(props: ITabsProps) {
  const { bw, value, onChange, className, ...rest } = props;
  const [activeTabIndex, setActiveTabIndex] = React.useState(
    props.items.findIndex((item) => item.id === value),
  );

  React.useEffect(() => {
    const index = props.items.findIndex((item) => item.id === value);

    setActiveTabIndex(index);
  }, [value]);

  return (
    <TPATabs
      onTabClick={handleTabClick}
      activeTabIndex={activeTabIndex}
      className={cls(classes.root, className, {
        [classes.bw]: bw,
      })}
      {...rest}
    />
  );

  function handleTabClick(index: number) {
    const active = props.items[index];

    setActiveTabIndex(index);

    return onChange(active.id as string);
  }
}

export { TabsAlignment, TabsSkin, TabsVariant };
