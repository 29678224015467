import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import {
  groupsCreateGroupClick,
  groupsLivesiteClick,
  groupsSearchForGroup,
} from '@wix/bi-logger-groups/v2';

import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';

import {
  selectGroups,
  selectIsLoggedIn,
  selectCanCreateGroup,
  selectCanCreateGroupRequests,
} from 'store/selectors';
import { EGroupsNamespace } from 'store/groups/constants';

import { BIUserEntry } from 'common/bi-logger/types';
import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';
import { useBiParams } from 'common/hooks/useBiParams';

import { groupsSettingsParams } from 'settings/groups';

import { Box } from 'wui/Box';
import { Button } from 'wui/Button';
import { Stack } from 'wui/Stack';
import { TextField } from 'wui/TextField';
import { Typography } from 'wui/Typography';
import { Show } from 'wui/Show';
import { Divider } from 'wui/Divider';

import { EmptyGroupList } from '../../../EmptyGroupList';
import { GroupList } from '../GroupList';

import { classes } from './Groups.st.css';

export function Groups() {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();
  const biParams = useBiParams();

  const settings = useSettings();

  const { groups$, application$ } = useController();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const joined = useSelector(selectGroups(EGroupsNamespace.JOINED));
  const suggested = useSelector(selectGroups(EGroupsNamespace.SUGGESTED));
  const canCreateGroup = useSelector(selectCanCreateGroup);
  const canCreateGroupRequests = useSelector(selectCanCreateGroupRequests);

  const showSuggestedGroups = settings.get(
    groupsSettingsParams.showGroupsToJoin,
  );
  const showCreateGroupButton =
    settings.get(groupsSettingsParams.showCreateGroupButton) &&
    (canCreateGroup || canCreateGroupRequests);
  const sectionTitleAlignment = settings.get(
    groupsSettingsParams.headerAlignment,
  );

  const [query, setQuery] = useState('');

  const queryGroups = useCallback(
    debounce((title: string) => {
      bi.report(
        groupsSearchForGroup({
          origin: 'sidebar_livesite',
          userEntry: BIUserEntry.SITE,
        }),
      );

      if (showSuggestedGroups) {
        groups$.query({
          title,
          namespace: EGroupsNamespace.SUGGESTED,
          partition: EGroupsNamespace.SUGGESTED,
        });
      }

      groups$.query({
        title,
        namespace: EGroupsNamespace.JOINED,
        partition: EGroupsNamespace.JOINED,
      });
    }, 500),
    [],
  );

  useDidUpdate(() => {
    queryGroups(query);
  }, [query, showSuggestedGroups]);

  return (
    <Box direction="vertical" gap="SP6" height="100%">
      <Show if={settings.get(groupsSettingsParams.showSearchSorting)}>
        <TextField
          bw={isMobile}
          value={query}
          withClearButton
          onClear={handleReset}
          prefix={<SearchIcon />}
          placeholder={t('groups-web.search.placeholder')}
          onChange={handleQueryChange}
          className={classes.search}
        />
      </Show>
      <div className={classes.content}>
        <Stack
          direction="vertical"
          separator={<Divider bw={isMobile} />}
          gap={isMobile ? 'SP4' : 'SP6'}
        >
          <Show if={isLoggedIn}>
            <section>
              <Typography
                as="h2"
                variant="p2-16"
                className={!isMobile ? classes.sectionTitle : undefined}
                align={sectionTitleAlignment}
              >
                {t('groups-web.tabs.my-groups')}
              </Typography>
              <GroupList
                groups={joined.groups}
                meta={joined.meta}
                status={joined.status}
                onRetry={handleJoinedRetry}
                onLoadMore={handleJoinedLoadMore}
                emptyState={
                  <EmptyGroupList
                    bw={isMobile}
                    variant="section"
                    filters={{ title: query }}
                    partition={EGroupsNamespace.JOINED}
                  />
                }
              />
            </section>
          </Show>
          <Show if={showSuggestedGroups}>
            <section>
              <Typography
                as="h2"
                variant="p2-16"
                className={!isMobile ? classes.sectionTitle : undefined}
                align={sectionTitleAlignment}
              >
                {t('groups-web.tabs.suggested-groups')}
              </Typography>

              <GroupList
                groups={suggested.groups}
                meta={suggested.meta}
                status={suggested.status}
                onRetry={handleSuggestedRetry}
                onLoadMore={handleSuggestedLoadMore}
                emptyState={
                  <EmptyGroupList
                    bw={isMobile}
                    variant="section"
                    filters={{ title: query }}
                    partition={EGroupsNamespace.SUGGESTED}
                  />
                }
              />
            </section>
          </Show>
        </Stack>
      </div>
      <Show if={showCreateGroupButton}>
        <Button secondary fullWidth onClick={openCreateGroupDialog}>
          {t('groups-web.btn.create-new')}
        </Button>
      </Show>
    </Box>
  );

  function openCreateGroupDialog() {
    bi.report(
      groupsCreateGroupClick({
        origin: 'new_layout_sidebar',
        userEntry: BIUserEntry.SITE,
      }),
    );
    application$.showDialog({ dialog: 'createGroup' });
  }

  function handleQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
  }

  function handleReset() {
    setQuery('');
  }

  function handleJoinedLoadMore() {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreenWithTab(EGroupsNamespace.JOINED),
        button_name: 'show_more_groups',
      }),
    );
    groups$.query({
      title: query,
      offset: joined.groups.length,
      namespace: EGroupsNamespace.JOINED,
      partition: EGroupsNamespace.JOINED,
    });
  }

  function handleSuggestedLoadMore() {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreenWithTab(EGroupsNamespace.SUGGESTED),
        button_name: 'show_more_groups',
      }),
    );
    groups$.query({
      title: query,
      offset: suggested.groups.length,
      namespace: EGroupsNamespace.SUGGESTED,
      partition: EGroupsNamespace.SUGGESTED,
    });
  }

  function handleJoinedRetry() {
    groups$.query({
      title: query,
      namespace: EGroupsNamespace.JOINED,
      partition: EGroupsNamespace.JOINED,
    });
  }

  function handleSuggestedRetry() {
    groups$.query({
      title: query,
      namespace: EGroupsNamespace.SUGGESTED,
      partition: EGroupsNamespace.SUGGESTED,
    });
  }
}

Groups.displayName = 'Groups';
