import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import {
  groupFeedTopicsAddTopicClickIntent,
  groupFeedTopicsTopicActions,
} from '@wix/bi-logger-groups/v2';

import type { ITopic } from 'api/topics/types';
import {
  selectFeedPermissionsByGroup,
  selectLastCreatedTopic,
  filterTopics,
  selectTopicsStatus,
} from 'store/selectors';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { TextField } from 'wui/TextField';
import { Typography } from 'wui/Typography';
import { Skeleton } from 'wui/Skeleton';
import { EmptyState } from 'wui/EmptyState';
import { Stack } from 'wui/Stack';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa';
import { Plus as PlusIcon } from '@wix/wix-ui-icons-common/on-stage';

import { Topic } from '../../Topic';

import { classes } from './TopicsForm.st.css';

interface IProps {
  isNewPost?: boolean;
  groupId: string;
  value: ITopic[];

  onChange(topics: ITopic[]): void;
}

export function TopicsForm(props: IProps) {
  const { groupId, value, onChange, isNewPost } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { topics$ } = useController();

  const [query, setQuery] = React.useState('');

  const topics = useSelector(filterTopics(query));
  const statuses = useSelector(selectTopicsStatus);
  const lastCreated = useSelector(selectLastCreatedTopic);
  const permissions = useSelector(selectFeedPermissionsByGroup(groupId));

  useDidUpdate(() => {
    if (lastCreated) {
      handleSelectTopic(lastCreated);
    }
  }, [lastCreated?.id]);

  return (
    <Box direction="vertical" gap="SP2" className={classes.root}>
      <TextField
        bw
        value={query}
        withClearButton
        onChange={handleQueryChange}
        onClear={handleQueryClear}
        maxLength={40}
        placeholder={t('groups-web.discussion.topics.modal.search.placeholder')}
      />
      <Typography variant="p2-14">
        {permissions.canCreateTopic
          ? t('groups-web.discussion.topics.modal.status-label.admin')
          : t('groups-web.discussion.topics.modal.status-label.member')}
      </Typography>

      <Show if={statuses.fetch.pending}>
        <Skeleton variant="rect" width="40%" height="20px" />
        <Skeleton variant="rect" width="30%" height="20px" />
        <Skeleton variant="rect" width="45%" height="20px" />
      </Show>

      <Hide if={statuses.fetch.pending}>
        <Hide if={!!topics.length}>
          <EmptyState
            bw
            variant="section"
            subtitle={getEmptyStateLabel()}
            action={
              !!query && permissions.canCreateTopic ? (
                <Box verticalAlign="middle">
                  <TextButton
                    prefixIcon={<PlusIcon />}
                    disabled={statuses.create.pending}
                    priority={TextButtonPriority.secondary}
                    onClick={() => handleCreateTopic(query)}
                  >
                    {t('groups-web.discussion.topics.modal.create-button')}
                  </TextButton>
                  <Topic bw topic={{ displayName: query, count: 0 }} />
                </Box>
              ) : undefined
            }
          />
        </Hide>
        <Show if={!!topics.length}>
          <Stack direction="vertical" gap="SP1" className={classes.list}>
            {topics.map((topic) => (
              <Topic
                bw
                key={topic.id}
                topic={topic}
                onClick={handleSelectTopic}
                onRemove={handleRemoveTopic}
                active={isSelected(topic.id)}
                isRemovable={isSelected(topic.id)}
              />
            ))}
          </Stack>
          <Show if={!!query && permissions.canCreateTopic}>
            <Box verticalAlign="middle">
              <TextButton
                prefixIcon={<PlusIcon />}
                disabled={statuses.create.pending}
                priority={TextButtonPriority.secondary}
                onClick={() => handleCreateTopic(query)}
              >
                {t('groups-web.discussion.topics.modal.create-button')}
              </TextButton>
              <Topic bw topic={{ displayName: query, count: 0 }} />
            </Box>
          </Show>
        </Show>
      </Hide>
    </Box>
  );

  function getEmptyStateLabel() {
    if (query) {
      return t('groups-web.discussion.topics.modal.status-label.filters-empty');
    }

    return permissions.canCreateTopic
      ? t('groups-web.discussion.topics.modal.status-label.empty.admin')
      : t('groups-web.discussion.topics.modal.status-label.empty.member');
  }

  function handleQueryClear() {
    setQuery('');
  }

  function handleQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
  }

  function isSelected(id?: string) {
    return value.some((topic) => topic.id === id);
  }

  function handleRemoveTopic(topic: ITopic) {
    onChange(value.filter(({ id }) => id !== topic.id));

    bi.report(
      groupFeedTopicsTopicActions({
        groupId,
        action: 'delete',
        origin: !isNewPost ? 'post_creation' : 'post_to_the_topic',
        topicName: topic.displayName,
        topicId: topic.id,
      }),
    );
  }

  function handleSelectTopic(topic: ITopic) {
    onChange([...value, topic]);

    if (!isNewPost) {
      bi.report(
        groupFeedTopicsTopicActions({
          groupId,
          action: 'edit',
          origin: 'post_to_the_topic',
          topicName: topic.displayName,
          topicId: topic.id,
        }),
      );
    } else {
      bi.report(
        groupFeedTopicsAddTopicClickIntent({
          groupId,
          origin: 'topics_list_in_post',
        }),
      );
    }
  }

  function handleCreateTopic(name: string) {
    topics$.create(groupId, name);

    bi.report(
      groupFeedTopicsAddTopicClickIntent({
        groupId,
        origin: 'topics_list_plus_btn_in_post',
      }),
    );
  }
}

TopicsForm.displayName = 'TopicsForm';
