import {
  FeedItemPermissions,
  FeedPermissions,
  FeedItem,
  FeedItemEntity,
  Users,
} from '@wix/ambassador-feed-v1-feed-item/types';

import { IGroupMember } from '../members/types';
import { ITopic } from '../topics/types';

export interface IUsers extends Omit<Users, 'userIds'> {
  users?: IGroupMember[];
}

export interface IUserReaction {
  user: IGroupMember;
  code: string;
  reactionTime?: Date;
  params?: Record<string, string>;
}

export interface IReactionsSummary {
  total: number;
  reactions: IUserReaction[];
}

export interface IFeedItem
  extends Omit<FeedItem, 'createdBy' | 'entity' | 'reactions' | 'activity'> {
  entity: IFeedItemEntity;
  activity?: IFeedItemActivity;
  createdBy: IGroupMember;
  reactions: IReactionsSummary;
}
export interface IFeedItemPermissions extends FeedItemPermissions {}
export interface IFeedPermissions extends FeedPermissions {}
export interface IFeedItemEntity extends Omit<FeedItemEntity, 'topics'> {
  topics: ITopic[];
}

export interface IFeedItemActivity {
  activityType: FeedItemActivityType;
  data: IFeedItemActivityData;
}

export interface IFeedListResponse {
  items: IFeedItem[];
  permissions: FeedPermissions;
  prevCursor: string;
  nextCursor: string;
  total: number;
}

export enum FeedItemActivityType {
  DRAFT_JS = 'DRAFTJS',
  GROUP_COVER_CHANGED = 'GROUP_COVER_CHANGED',
  ABOUT_GROUP_CHANGED = 'ABOUT_GROUP_CHANGED',
  USERS_JOINED = 'USERS_JOINED',
}

export type IFeedItemActivityData =
  | IGroupCoverChangedActivity
  | IAboutGroupChangedActivity
  | IUsersJoinedActivity
  | IDraftJSActivity;

export interface IGroupCoverChangedActivity {
  hasCoverBefore: boolean;
  src: string;
}

export interface IAboutGroupChangedActivity {
  content: string;
}

export interface IUsersJoinedActivity {
  authorUserId: string;
  userIds: string[];
  users: { [id: string]: IGroupMember };
}

export interface IDraftJSActivity {
  content: string;
  activity: string;
}

export { Mode as ReactionMode } from '@wix/ambassador-reactions-v1-identity-reaction/types';
