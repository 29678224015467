import React, { useEffect } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { EGroupsNamespace } from 'store/groups/constants';
import { useController } from 'common/context/controller';

import { groupsSettingsParams } from 'settings/groups';

import { InputDialog } from 'wui/InputDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';

import { Groups } from '../Groups';
import { SIDEBAR_GROUPS_DIALOG_ROOT } from './dataHooks';

export function GroupsDialog(props: React.ComponentProps<typeof InputDialog>) {
  const { t } = useTranslation();

  const settings = useSettings();
  const showSuggestedGroups = settings.get(
    groupsSettingsParams.showGroupsToJoin,
  );

  const { groups$ } = useController();

  useEffect(() => {
    if (props.isOpen) {
      if (showSuggestedGroups) {
        groups$.query({
          namespace: EGroupsNamespace.SUGGESTED,
          partition: EGroupsNamespace.SUGGESTED,
        });
      }

      groups$.query({
        namespace: EGroupsNamespace.JOINED,
        partition: EGroupsNamespace.JOINED,
      });
    }
  }, [props.isOpen]);

  return (
    <InputDialog
      {...props}
      paperProps={{ 'data-hook': SIDEBAR_GROUPS_DIALOG_ROOT }}
    >
      <DialogTitle title={t('groups-web.group-list.label')} />
      <DialogContent>
        <Groups />
      </DialogContent>
    </InputDialog>
  );
}

GroupsDialog.displayName = 'GroupsDialog';
