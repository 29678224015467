import React from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from '@wix/yoshi-flow-editor';

import type { IUsersJoinedActivity } from 'api/feed/types';
import { selectGroupName, selectGroupSlugById } from 'store/selectors';

import { Link } from '../../Link';
import { ProfileLink } from '../../ProfileLink';

import { IActivityDescriptionProps } from './types';

import { classes } from './ActivityDescription.st.css';

export function UsersJoined(props: IActivityDescriptionProps) {
  const { item, full } = props;

  const authorId = item.createdBy.memberId;
  const data = item.activity?.data as IUsersJoinedActivity;
  const groupId = item.applicationContext?.contextId as string;

  const { t } = useTranslation();
  const slug = useSelector(selectGroupSlugById(groupId));
  const groupName = useSelector(selectGroupName(groupId));

  const userId = data.userIds.find((id) => id !== authorId);
  const user = data.users[userId as string];

  if (!full) {
    return (
      <Trans
        i18nKey="groups-web.activity-post.2users.joined"
        values={{
          userName: user.name || t('groups-web.member.anonymous'),
          groupName,
        }}
        components={[
          <span className={classes.opacity} />,
          <ProfileLink profile={item.createdBy} />,
        ]}
      />
    );
  }

  return (
    <Trans
      i18nKey="groups-web.activity-post.2users.joined.with-group-name"
      values={{
        userName: user.name || t('groups-web.member.anonymous'),
        groupName,
      }}
      components={[
        <span className={classes.opacity} />,
        <ProfileLink profile={item.createdBy} />,
        <Link state="group" params={{ slug }} />,
      ]}
    />
  );
}

UsersJoined.displayName = 'UsersJoined';
