import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';

import { Badge, BadgePriority } from 'wix-ui-tpa';

import type { IBadge } from 'api/badges/types';

import { Stack } from 'wui/Stack';

import settingsParams from 'Group/settingsParams';

import { MEMBER_BADGES_ITEM, MEMBER_BADGES_LIST } from './dataHook';

interface IProps {
  limit?: number;
  badges?: IBadge[];
}

export function MemberBadges(props: IProps) {
  const { limit, badges } = props;

  const settings = useSettings();
  const showBadges = settings.get(settingsParams.showMembersBadges);

  if (!badges?.length || !showBadges) {
    return null;
  }

  const items = badges.slice(0, limit);

  return (
    <Stack gap="SP1" data-hook={MEMBER_BADGES_LIST}>
      {items.map((badge) => (
        <Badge
          key={badge.id}
          data-hook={MEMBER_BADGES_ITEM}
          priority={BadgePriority.secondary}
        >
          {badge.title}
        </Badge>
      ))}
    </Stack>
  );
}

MemberBadges.displayName = 'MemberBadges';
MemberBadges.defaultProps = {
  limit: 2,
};
