import React from 'react';
import type { Components } from 'react-virtuoso';
import { ErrorMonitorBoundary } from '@wix/yoshi-flow-editor';

import { ErrorState } from 'wui/ErrorState';
import { Fade } from 'wui/Fade';

import type { IVirtuosoContext } from '../types';

export const Item: Components<IVirtuosoContext>['Item'] = (props) => {
  const context = props.context!;

  return (
    <Fade {...props}>
      <ErrorMonitorBoundary
        fallback={<ErrorState unhandled onRetry={context.onRetry} />}
      >
        {props.children}
      </ErrorMonitorBoundary>
    </Fade>
  );
};
