import React, { useCallback, useEffect, useRef } from 'react';
import type { ListRange, VirtuosoHandle } from 'react-virtuoso';
import { useEnvironment } from '@wix/yoshi-flow-editor';

interface IProps {
  size: number;
}

export function useKeyboardScroll(props: IProps) {
  const { size } = props;
  const { isSSR, isMobile, isEditor } = useEnvironment();

  const $virtuoso = useRef<VirtuosoHandle>(null);
  const [index, setIndex] = React.useState(-1);

  const keyDownHandler = useCallback(
    (event) => {
      let next: number = -1;
      const key: number = event.which || event.keyCode;

      switch (key) {
        // PageUp
        case 33:
          next = Math.max(0, index - 1);
          break;

        // PageDown
        case 34:
          next = Math.min(size, index + 1);
          break;

        // Home
        case 36:
          next = 0;
          break;

        // End
        case 35:
          break;

        default:
          return;
      }

      if (next !== -1) {
        event.preventDefault();
        $virtuoso.current?.scrollToIndex(index);
        setIndex(next);
      }
    },
    [index, size, setIndex],
  );

  useEffect(() => {
    if (isMobile || isEditor || isSSR) {
      return;
    }

    window.addEventListener('keydown', keyDownHandler);

    return function () {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler]);

  return {
    $virtuoso,
    focused: index,
    focus: setIndex,
    handleRangeChanged,
  };

  function handleRangeChanged(range: ListRange) {
    setIndex(range.startIndex);
  }
}
