import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import type { IGroupMember, IMember } from 'api/members/types';

import { Show } from 'wui/Show';
import { ListItem } from 'wui/ListItem';
import { Checkbox } from 'wui/Checkbox';
import { ListItemAvatar } from 'wui/ListItemAvatar';
import { ListItemAction } from 'wui/ListItemAction';
import { ListItemText } from 'wui/ListItemText';
import { ListItemButton } from 'wui/ListItemButton';
import { Typography } from 'wui/Typography';
import { Avatar, AvatarSize } from 'wix-ui-tpa';

import settingsParams from 'Group/settingsParams';

import { RoleIcon } from '../RoleIcon';
import { ProfileLink } from '../ProfileLink';
import { MemberBadges } from '../MemberBadges';

interface IProps extends Partial<React.ComponentProps<typeof ListItem>> {
  bw?: boolean;
  selected?: boolean;
  profile: IMember | IGroupMember;
  children?: React.ReactNode;

  avatarProps?: Partial<React.ComponentProps<typeof Avatar>>;

  onClick?(): void;
}

export function ProfileListItem(props: IProps) {
  const { bw, profile, children, avatarProps, selected, onClick, ...rest } =
    props;

  const { t } = useTranslation();
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  const showBadges = settings.get(settingsParams.showMembersBadges);

  if (onClick) {
    return (
      <ListItem {...rest}>
        <ListItemButton onClick={onClick} disablePadding>
          <Show if={typeof selected !== undefined}>
            <ListItemAction>
              <Checkbox bw={bw} checked={selected} onChange={onClick} />
            </ListItemAction>
          </Show>
          <ListItemAvatar>
            <Avatar
              lettersLimit={2}
              name={profile.name}
              src={profile.imageUrl}
              size={isMobile ? AvatarSize.medium : AvatarSize.large}
              {...avatarProps}
            />
          </ListItemAvatar>
          <ListItemText
            title={
              <Typography
                noWrap
                variant={isMobile ? 'p2-14' : 'p2-16'}
                as="div"
              >
                {profile.name || t('groups-web.member.anonymous')}
                <RoleIcon role={(profile as IGroupMember).role} />
              </Typography>
            }
            subtitle={showBadges && <MemberBadges badges={profile.badges} />}
          />
          {children ? <ListItemAction>{children}</ListItemAction> : null}
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <ListItem {...rest}>
      <ListItemAvatar>
        <ProfileLink profile={profile} tabIndex={-1}>
          <Avatar
            lettersLimit={2}
            name={profile.name}
            src={profile.imageUrl}
            size={isMobile ? AvatarSize.medium : AvatarSize.large}
            {...avatarProps}
          />
        </ProfileLink>
      </ListItemAvatar>
      <ListItemText
        title={
          <Typography noWrap variant={isMobile ? 'p2-14' : 'p2-16'} as="div">
            <ProfileLink profile={profile} />
            <RoleIcon role={(profile as IGroupMember).role} />
          </Typography>
        }
        subtitle={showBadges && <MemberBadges badges={profile.badges} />}
      />
      {children ? <ListItemAction>{children}</ListItemAction> : null}
    </ListItem>
  );
}

ProfileListItem.displayName = 'ProfileListItem';
