import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import type { IFeedItem } from 'api/feed/types';
import { selectGroupSlugById, selectIsGroupSecret } from 'store/selectors';

import {
  Share as ShareIcon,
  Comment as CommentIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { useHref } from 'common/router';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { ShareDialog } from 'wui/ShareDialog';
import { Divider } from 'wui/Divider';
import { CardContent } from 'wui/CardContent';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa';

import settingsParams from 'Group/settingsParams';

import { Reactions } from './Reactions';

interface IProps {
  item: IFeedItem;

  openCommentForm(): void;
}

export function FeedItemActions(props: IProps) {
  const { item, openCommentForm } = props;

  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;

  const slug = useSelector(selectGroupSlugById(groupId));
  const isGroupSecret = useSelector(selectIsGroupSecret(groupId));

  const feedItemUrl = useHref(
    'group.discussion.post',
    { slug, feedItemId },
    { absolute: true },
  );

  const [shareDialogOpened, setShareDialogOpened] = useState(false);

  const canShare =
    settings.get(settingsParams.showShareButton) &&
    !isGroupSecret &&
    !!item.permissions?.canShare;

  return (
    <CardContent gap="SP2" disableSideGutters={isMobile}>
      <Divider />
      <Box align="space-between" verticalAlign="middle">
        <Box width="100%" align="center">
          <Reactions item={item} />
        </Box>
        <Box width="100%" align="center">
          <TextButton
            prefixIcon={<CommentIcon />}
            onClick={openCommentForm}
            priority={TextButtonPriority.secondary}
          >
            {t('groups-web.feed.feed-item.social-bar.comment')}
          </TextButton>
        </Box>

        <Box width="100%" align="center">
          <Show if={canShare}>
            <TextButton
              prefixIcon={<ShareIcon />}
              priority={TextButtonPriority.secondary}
              onClick={openShareDialog}
            >
              {t('groups-web.feed.feed-item.social-bar.share')}
            </TextButton>
          </Show>
        </Box>
      </Box>
      <Divider />

      <ShareDialog
        data={{ url: feedItemUrl }}
        isOpen={shareDialogOpened}
        canUseNative={isMobile}
        onClose={closeShareDialog}
        title={t('groups-web.discussion.feed.post-actions.share.dialog')}
      />
    </CardContent>
  );

  function openShareDialog() {
    setShareDialogOpened(true);
  }

  function closeShareDialog() {
    setShareDialogOpened(false);
  }
}

FeedItemActions.displayName = 'FeedItemActions';
