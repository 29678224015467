import React from 'react';
import {
  Collapse as ReactCollapse,
  UnmountClosed,
  CollapseProps,
} from 'react-collapse';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { classes } from './Collapse.st.css';

type ReactCollapseTheme = CollapseProps['theme'];

interface IProps extends CollapseProps, TPAComponentProps {
  unmountOnExit?: boolean;
}

export function Collapse(props: IProps) {
  const { unmountOnExit, ...rest } = props;

  const Component = unmountOnExit ? UnmountClosed : ReactCollapse;

  return <Component theme={classes as ReactCollapseTheme} {...rest} />;
}

Collapse.displayName = 'Collapse';
