import React from 'react';
import { Components } from 'react-virtuoso';
import { TransitionGroup } from 'react-transition-group';

import { IVirtuosoContext } from '../types';
import { classes } from '../FeedItemList.st.css';

export const List: Components<IVirtuosoContext>['List'] = React.forwardRef(
  (props, ref) => {
    return (
      <div {...props} className={classes.list} ref={ref}>
        {/* Exit animation should not be present otherwise infinite scroll will have fucked up animations */}
        <TransitionGroup exit={false} component={null}>
          {props.children}
        </TransitionGroup>
      </div>
    );
  },
);
