import React from 'react';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

import { EGroupPartition } from 'api/groups/types';

import { useCurrentStateAndParams, useRouter } from 'common/router';
import { useBiParams } from 'common/hooks/useBiParams';

import { Tabs as TabsComponent, TabsAlignment, TabsVariant } from 'wui/Tabs';

export function Tabs() {
  const router = useRouter();
  const { params } = useCurrentStateAndParams();
  const { t } = useTranslation();

  const bi = useBi();
  const biParams = useBiParams();

  return (
    <TabsComponent
      value={params.partition}
      onChange={handleTabChange}
      alignment={TabsAlignment.left}
      variant={TabsVariant.fit}
      items={[
        {
          id: EGroupPartition.ALL,
          title: t('groups-web.tabs.all'),
        },
        {
          id: EGroupPartition.JOINED,
          title: t('groups-web.tabs.my-groups'),
        },
        {
          id: EGroupPartition.SUGGESTED,
          title: t('groups-web.tabs.suggested-groups'),
        },
      ]}
    />
  );

  function handleTabChange(id: string) {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreen,
        button_name: biParams.activeTab(id as EGroupPartition),
      }),
    );
    router.go('.', { partition: id });
  }
}
