import type { Experiments } from '@wix/yoshi-flow-editor';

export class RCEConfigBuilder<T> {
  private entries: Set<T>;
  constructor(private experiments: Experiments, initialState: T[] = []) {
    this.entries = new Set(initialState);
  }

  add(entry: T, experiments?: string[]): RCEConfigBuilder<T> {
    if (
      (experiments &&
        experiments.some((experimentName) =>
          this.experiments.enabled(experimentName),
        )) ||
      !experiments
    ) {
      this.entries.add(entry);
    }
    return this;
  }

  delete(entry: T, experimentName?: string): RCEConfigBuilder<T> {
    if (
      (experimentName && this.experiments.enabled(experimentName)) ||
      !experimentName
    ) {
      this.entries.delete(entry);
    }
    return this;
  }

  build(): T[] {
    return Array.from(this.entries);
  }
}
