import React, { useEffect } from 'react';
import cls from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { SwitchTransition } from 'react-transition-group';

import {
  Camera as CameraIcon,
  Replace as ReplaceIcon,
  Delete as DeleteIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import type { CoverImage } from '@wix/ambassador-social-groups-v2-group/types';
import {
  WowImage,
  ImageResizeOptions,
  ImageHoverEffectOptions,
  ButtonSize,
} from 'wix-ui-tpa';

import { Button } from 'wui/Button';
import { Fade } from 'wui/Fade';
import { ButtonGroup } from 'wui/ButtonGroup';

import { classes } from './CoverImageEditor.st.css';

export interface IEditableCoverImage extends CoverImage {
  file?: File;
}

interface IProps {
  value: IEditableCoverImage;

  onChange(value: IEditableCoverImage): void;
}

export function CoverImageEditor(props: IProps) {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const $input = React.useRef<HTMLInputElement>(null);

  const [media, setMedia] = React.useState(props.value?.image?.mediaId);

  useEffect(() => {
    setMedia(props.value?.image?.mediaId);
  }, [props.value?.image?.mediaId]);

  return (
    <div
      className={cls(classes.root, {
        [classes.mobile]: isMobile,
      })}
    >
      <SwitchTransition>
        {(() => {
          if (!media) {
            return (
              <Fade key="empty">
                <Button
                  bw
                  secondary
                  onClick={handleUploadClick}
                  prefixIcon={<CameraIcon />}
                  size={isMobile ? ButtonSize.tiny : ButtonSize.medium}
                >
                  {t('groups-web.group.settings.details.upload_image')}
                </Button>
              </Fade>
            );
          }

          return (
            <Fade key={media}>
              <>
                <WowImage
                  src={media as string}
                  className={classes.image}
                  resize={ImageResizeOptions.cover}
                  hoverEffect={ImageHoverEffectOptions.zoom}
                />
                <ButtonGroup
                  className={classes.actions}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                >
                  <Button
                    bw
                    secondary
                    fullWidth
                    onClick={handleUploadClick}
                    prefixIcon={<ReplaceIcon />}
                    size={isMobile ? ButtonSize.tiny : ButtonSize.medium}
                  >
                    {t('groups-web.group.settings.details.change_image')}
                  </Button>
                  <Button
                    bw
                    fullWidth
                    onClick={handleDeleteClick}
                    prefixIcon={<DeleteIcon />}
                    size={isMobile ? ButtonSize.tiny : ButtonSize.medium}
                  >
                    {t('groups-web.group.settings.details.delete_image')}
                  </Button>
                </ButtonGroup>
              </>
            </Fade>
          );
        })()}
      </SwitchTransition>

      <input
        hidden
        accept="image/*"
        type="file"
        ref={$input}
        onChange={handleFileInputChange}
      />
    </div>
  );

  function handleDeleteClick() {
    props.onChange({});
    setMedia(undefined);
  }

  function handleUploadClick() {
    $input.current?.click();
  }

  function handleFileInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const [file] = Array.from(event.target.files!);

    if (media) {
      URL.revokeObjectURL(media);
    }

    setMedia(URL.createObjectURL(file));

    event.target.value = null as any;
    props.onChange({ ...props.value, file });
  }
}

CoverImageEditor.displayName = 'CoverImageEditor';
