import React from 'react';
import cls from 'classnames';

import {
  Dropdown as TPADropdown,
  DropdownOptionProps,
  DropdownProps,
} from 'wix-ui-tpa';
import { usePortalContainer } from 'wui/Portal';

import { stVars } from '../theme/z-index.st.css';

import { classes } from './Dropdown.st.css';

interface IDropdownProps extends DropdownProps {
  bw?: boolean;
}

export function Dropdown(props: IDropdownProps) {
  const { className, bw, ...rest } = props;

  const container = usePortalContainer();

  return (
    <TPADropdown
      fixed
      upgrade
      appendTo={container}
      className={cls(classes.root, className, {
        [classes.bw]: bw,
      })}
      optionsContainerZIndex={stVars.zIndex_TOOLTIP as unknown as number}
      {...rest}
    />
  );
}

Dropdown.displayName = 'Dropdown';

export type { DropdownOptionProps };
