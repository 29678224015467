import { IGroupSettingsParams, groupSettingsParams } from 'settings/group';
import {
  IAppearanceSettingsParams,
  appearanceSettingsParams,
} from 'settings/appearance';

export type ISettingsParams = IGroupSettingsParams & IAppearanceSettingsParams;

export default {
  ...appearanceSettingsParams,
  ...groupSettingsParams,
};
